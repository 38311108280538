import Vue from 'vue'
import VueRouter from 'vue-router'

//Adding layouts router.
const BlankLayout = () => import("@/layouts/BlankLayout")
const Layout1 = () => import("@/layouts/backend/Layout-1")

//Adding page content router.
const Dashbord1 = () => import('@/views/backend/Dashboards/Dashbord1')

//auth elements
const SignIn = () => import('@/views/backend/Auth/SignIn')
const SignUp = () => import('@/views/backend/Auth/SignUp')
const RecoverPassword = () => import('@/views/backend/Auth/RecoverPassword')
const LockScreen = () => import('@/views/backend/Auth/LockScreen')
const ConfirmMail = () => import('@/views/backend/Auth/ConfirmMail')

//pages elements
const Error404 = () => import('@/views/backend/Pages/Error/Error404')
const Error500 = () => import('@/views/backend/Pages/Error/Error500')
const BlankPage = () => import('@/views/backend/Pages/BlankPage')
const FAQ = () => import('@/views/backend/Pages/FAQ')
const Maintainance = () => import('@/views/backend/Pages/Maintainance')

//app element
const UserAccountSetting = () =>import('@/views/backend/App/User Management/UserAccountSetting')
const UserAdd = () =>import('@/views/backend/App/User Management/UserAdd')
const UserList = () =>import('@/views/backend/App/User Management/UserList')
const UserProfile = () =>import('@/views/backend/App/User Management/UserProfile')
const userPrivacySettings = () =>import('@/views/backend/App/User Management/UserPrivacySetting')
const UserProfileEdit = () =>import('@/views/backend/App/User Management/UserProfileEdit')
const VacationMap  = () =>import('@/views/backend/App/HumanResourcesManagement/VacationMap')
const AbsencesMap  = () =>import('@/views/backend/App/HumanResourcesManagement/AbsencesMap')
const LearningMap  = () =>import('@/views/backend/App/HumanResourcesManagement/LearningMap')
const TaskBoard  = () =>import('@/views/backend/App/HumanResourcesManagement/TaskBoard')
const ManageNetworks  = () =>import('@/views/backend/App/MarketingManagement/ManageNetworks')
const MarketingWebsite  = () =>import('@/views/backend/App/MarketingManagement/MarketingWebsite')
const MarketingSocials  = () =>import('@/views/backend/App/MarketingManagement/MarketingSocials')

//list
const InvoicingList = () =>import('@/views/backend/App/InvoicingManagement/InvoicingList')
const SalariesList = () =>import('@/views/backend/App/SalariesManagement/SalariesList')
const EmployeesList  = () =>import('@/views/backend/App/HumanResourcesManagement/EmployeesList')
const ArticlesList  = () =>import('@/views/backend/App/ArticlesManagement/ArticlesList')
const ClientsList  = () =>import('@/views/backend/App/ContactsManagement/ClientsList')
const SuppliersList = () =>import('@/views/backend/App/ContactsManagement/SuppliersList')
const PaymentsList  = () =>import('@/views/backend/App/AccountingManagement/PaymentsList')

// add
const PaymentsAdd  = () =>import('@/views/backend/App/AccountingManagement/PaymentsAdd')
const ClientAdd  = () =>import('@/views/backend/App/ContactsManagement/ClientAdd')
const SupplierAdd  = () =>import('@/views/backend/App/ContactsManagement/SupplierAdd')
const EmployeesAdd  = () =>import('@/views/backend/App/HumanResourcesManagement/EmployeesAdd')
const SalariesAdd = () =>import('@/views/backend/App/SalariesManagement/SalariesAdd')

// edit
const ClientEdit  = () =>import('@/views/backend/App/ContactsManagement/ClientEdit')
const EmployeesEdit  = () =>import('@/views/backend/App/HumanResourcesManagement/EmployeesEdit')
const SupplierEdit  = () =>import('@/views/backend/App/ContactsManagement/SupplierEdit')
const PaymentsEdit  = () =>import('@/views/backend/App/AccountingManagement/PaymentsEdit')


const Accountsetting =() => import('@/views/backend/Pages/Extrapages/AccountSettings')
const privacypolicy = () => import('@/views/backend/Pages/Extrapages/PrivacyPolicy')
const PrivacySettings = () =>import('@/views/backend/Pages/Extrapages/PrivacySettings')
const TermsOfUse = () =>import('@/views/backend/Pages/Extrapages/TermsOfUse')


Vue.use(VueRouter)

const childRoute = () => [
  {
    path: '',
    name: 'layout.dashboard1',
    meta: {  name: 'Dashboard1' },
    component: Dashbord1
  },
  {
    path: 'user-add',
    name: 'app.user-add',
    meta: {  name: 'user-add' },
    component:UserAdd
  },
  {
    path: 'user-list',
    name: 'app.user-list',
    meta: {  name: 'User List' },
    component:UserList
  },
  {
    path: 'user-profile',
    name: 'app.user-profile',
    meta: {  name: 'User Profile' },
    component:UserProfile
  },
  {
    path: 'user-privacy-settings',
    name: 'app.user-privacy-setting',
    meta: {  name: 'user-privacy-setting' },
    component:userPrivacySettings
  },
  {
    path: 'User-profile-edit',
    name: 'app.user-profile-edit',
    meta: {  name: 'user-profile-edit' },
    component:UserProfileEdit
  },
  {
    path: 'User-account-setting',
    name: 'app.user-Account-setting',
    meta: {  name: 'user account setting' },
    component:UserAccountSetting
  },
  {
    path: 'privacy-settings',
    name: 'app.Privacysettings',
    meta: {  name: 'Privacysettings' },
    component:PrivacySettings
  },
  {
    path: 'terms-of-use',
    name: 'app.Termsofuse',
    meta: {  name: 'Termsofuse' },
    component:TermsOfUse
  },
  {
    path: 'privacy-policy',
    name: 'app.privacypolicy',
    meta: {  name: 'privacypolicy' },
    component:privacypolicy
  },
  {
    path: 'account-setting',
    name: 'app.Accountsetting',
    meta: {  name: 'Accountsetting' },
    component:Accountsetting
  },
  {
    path: 'invoicing-list',
    name: 'app.invoicing-list',
    meta: {  name: 'Invoicing List' },
    component:InvoicingList
  },
  {
    path: 'salaries-list',
    name: 'app.salaries-list',
    meta: {  name: 'Salaries List' },
    component:SalariesList
  },
  {
    path: 'salaries-add',
    name: 'app.salaries-add',
    meta: {  name: 'Salaries Add' },
    component:SalariesAdd
  },
  {
    path: 'employees-list',
    name: 'app.employees-list',
    meta: {  name: 'Employees List' },
    component: EmployeesList
  },
  {
    path: 'app.vacations-map',
    name: 'app.vacations-map',
    meta: {  name: 'Vacation map' },
    component: VacationMap
  },
  {
    path: 'app.absences-map',
    name: 'app.absences-map',
    meta: {  name: 'Absences map' },
    component: AbsencesMap
  },
  {
    path: 'app.learning-map',
    name: 'app.learning-map',
    meta: {  name: 'Learning map' },
    component: LearningMap
  },
  {
    path: 'app.task-board',
    name: 'app.task-board',
    meta: {  name: 'Task board' },
    component: TaskBoard
  },
  {
    path: 'articles-list',
    name: 'app.articles-list',
    meta: {  name: 'Articles List' },
    component:ArticlesList
  },
  {
    path: 'manage-networks',
    name: 'app.manage-networks',
    meta: {  name: 'Manage Networks' },
    component:ManageNetworks
  },
  {
    path: 'marketingWebsite',
    name: 'app.marketingWebsite',
    meta: {  name: 'Marketing Website' },
    component:MarketingWebsite
  },
  {
    path: 'marketingSocials',
    name: 'app.marketingSocials',
    meta: {  name: 'Marketing Socials' },
    component:MarketingSocials
  },
  {
    path: 'clients-list',
    name: 'app.clients-list',
    meta: {  name: 'Clients List' },
    component:ClientsList
  },
  {
    path: 'suppliers-list',
    name: 'app.suppliers-list',
    meta: {  name: 'Suppliers List' },
    component: SuppliersList
  },
  {
    path: 'payments-list',
    name: 'app.payments-list',
    meta: {  name: 'Payments List' },
    component:PaymentsList
  },
  {
    path: 'payments-add',
    name: 'app.payments-add',
    meta: {  name: 'Payments Add' },
    component:PaymentsAdd
  },
  {
    path: 'client-add',
    name: 'app.client-add',
    meta: {  name: 'Client Add' },
    component:ClientAdd
  },
  {
    path: 'supplier-add',
    name: 'app.supplier-add',
    meta: {  name: 'Supplier Add' },
    component:SupplierAdd
  },
  {
    path: 'employees-add',
    name: 'app.employees-add',
    meta: {  name: 'Employees Add' },
    component:EmployeesAdd
  },
  {
    path: 'client-edit',
    name: 'app.client-edit',
    meta: {  name: 'Client Edit' },
    component:ClientEdit
  },
  {
    path: 'employees-edit',
    name: 'app.employees-edit',
    meta: {  name: 'Employees Edit' },
    component:EmployeesEdit
  },
  {
    path: 'supplier-edit',
    name: 'app.supplier-edit',
    meta: {  name: 'Supplier Edit' },
    component:SupplierEdit
  },
  {
    path: 'payments-edit',
    name: 'app.payments-edit',
    meta: {  name: 'Payments Edit' },
    component:PaymentsEdit
  },
]
const authchildRoute = () =>[
  {
    path: 'sign-in',
    name: 'auth.login',
    meta: {  name: 'SignIn' },
    component: SignIn
  },
  {
    path: 'sign-up',
    name: 'auth.register',
    meta: {  name: 'SignUp' },
    component: SignUp
  },
  {
    path: 'recover-password',
    name: 'auth.recover-password',
    meta: {  name: 'Recover Password' },
    component: RecoverPassword
  },
  {
    path: 'lock-screen',
    name: 'auth.lock-screen',
    meta: {  name: 'Lock Screen' },
    component: LockScreen
  },
  {
    path: 'confirm-mail',
    name: 'auth.confirm-mail',
    meta: {  name: 'Confirm Mail' },
    component: ConfirmMail
  },

]
const pageschildRoute = () =>[
  {
    path: 'error-404',
    name: 'error.404',
    meta: {  name: 'Error 404' },
    component: Error404
  },
  {
    path: 'error-500',
    name: 'error.500',
    meta: {  name: 'Error 500' },
    component: Error500
  },
  {
    path: 'pages-maintainance',
    name: 'pages.maintenance',
    meta: {  name: 'Maintaiance' },
    component: Maintainance
  }
]
const extrapageschildRoute = () =>[
  {
    path: 'pages-faq',
    name: 'pages.faq',
    meta: {  name: 'Pages FAQ' },
    component: FAQ
  },
  {
    path: 'pages-blank-page',
    name: 'pages.blank-page',
    meta: {  name: 'Pages Blank Page ' },
    component: BlankPage
  },
]

const routes = [
  {
    path: '/',
    name: '',
    component: Layout1,
    children: childRoute()
  },
  {
    path: '/auth',
    name: 'auth',
    component: BlankLayout,
    children: authchildRoute()
  },
  {
    path: '/pages',
    name: 'pages',
    component: BlankLayout,
    children: pageschildRoute()
  },
  {
    path: '/extra-pages',
    name: 'extra-pages',
    component: Layout1,
    children: extrapageschildRoute()
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/auth/sign-in', '/auth/sign-up', '/auth/recover-password', '/auth/confirm-mail'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/auth/sign-in');
  }
  if (!authRequired && loggedIn) {
    return next('/');
  }

  next();
})

export default router
