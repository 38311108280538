<template>
  <router-view/>
</template>
<script>
// import 'line-awesome/dist/line-awesome/css/line-awesome.min.css'
// import 'remixicon/fonts/remixicon.css'
export default {
  name: 'App',
  components: {
  },
  mounted () {
  }
}
</script>
<style lang="scss">
  @import "./assets/scss/backend.scss";
  @import "./assets/css/custom.css";
  
  .disabled
  {
    pointer-events: none;
    opacity: 0.5;
  }
</style>
